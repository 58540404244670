// export const strapi_url = "http://192.168.1.80:1337";
export const strapi_url = "https://cms-nea.scieverinc.com";
export const strapi_token =
  "0e7dbf167b8cada9e7db5c43591a77854314fb6cc11089a6bc31f25745132114d6d772e92818680252977cca5e95e64256ecb8ba905087661ffecc8fdc1834e574f8ff6d53f6465fae4fdc366d3afe424f704e1d009ba5633c936e1f1a6bb7a7e4c6e50da8b79e2047ece24a7f23cb76e89049e31b987765532e74bea8a8d579";

// export const backend_url = "http://localhost:3300/api/v1";
export const backend_url = "https://sgs-api.scieverinc.com";

export const IDENTITY_SERVER_URL = "https://nea.sis.scieverinc.com/";

// export const CLIENT_ID = "848f42db-556b-4b92-b335-9a5deccb243d"; // prod
export const CLIENT_ID = "b447314e-1080-4321-a0fa-3f381aff82ac"; // dev

// export const CALLBACK_URL = "https://nea.scieverinc.com/callback";
export const CALLBACK_URL = "http://localhost:3000/callback";
